<template>
    <div>
        <banner></banner>
        <div class="gongju-wrap w-1300">
            <div class="gengduo">
                <div class="center-text">
                    <p></p>
                    <span>{{title}}</span>
                </div>
                <div class="more" @click="goTo('tool',{id:id})">更多</div>
            </div>
            
            <div class="tuijian-list"  v-loading="loading">
                <div class="tuijian-item" v-for="(item,index) in ggList" :key="index" @click="goTo('tool_introduce',{id:item.id,wid:$route.query.wid})">
                    <div class="tuijian-img"><img :src="item.banner_url" alt=""></div>
                    <div class="tuijian-p">{{item.name}}</div>
                </div>              
            </div>

            <div class="meiyong-wrap">
                <div class="meiyong-text">
                    <div class="meiyong-p">{{guanggao[0]?guanggao[0].title:'服务产品，突破想象'}}</div>
                    <div class="meiyong-p1">{{guanggao[0]?guanggao[0].content:'专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！'}}</div>
                </div>
                <div class="meiyong-img"><img  :src="guanggao[0]?guanggao[0].pic_url:require('../../../assets/images/gongjubj.png')" alt=""></div>                
            </div>
        </div>
        <div class="tool-wrap">
            <div class="tool w-1300">
                <div class="tool-title">
                    <div class="tool-text">
                        <p></p>
                        <div class="tool-p">{{guanggao[1]?guanggao[1].title:'服务产品，突破想象'}}</div>
                        <p></p>
                    </div>
                    <div class="tool-p1">{{guanggao[1]?guanggao[1].content:'服务产品，突破想象'}}</div>
                </div>
                <div class="tool-list">
                    <div class="tool-item">
                        <div class="tool-img"><img src="../../../assets/images/tool1.png" alt=""></div>
                        <div class="tool-item-title">
                            <div class="tool-item-text">{{guanggao[2]?guanggao[2].title:'服务产品，突破想象'}}</div>
                            <div class="tool-item-p">{{guanggao[2]?guanggao[2].content:'服务产品，突破想象'}}</div>
                        </div>
                    </div>
                    <div class="tool-item">
                        <div class="tool-img"><img src="../../../assets/images/tool2.png" alt=""></div>
                        <div class="tool-item-title">
                            <div class="tool-item-text">{{guanggao[3]?guanggao[3].title:'服务产品，突破想象'}}</div>
                            <div class="tool-item-p">{{guanggao[3]?guanggao[3].content:'服务产品，突破想象'}}</div>
                        </div>
                    </div>
                    <div class="tool-item">
                        <div class="tool-img"><img src="../../../assets/images/tool3.png" alt=""></div>
                        <div class="tool-item-title">
                            <div class="tool-item-text">{{guanggao[4]?guanggao[4].title:'服务产品，突破想象'}}</div>
                            <div class="tool-item-p">{{guanggao[4]?guanggao[4].content:'服务产品，突破想象'}}</div>
                        </div>
                    </div>
                    <div class="tool-item">
                        <div class="tool-img"><img src="../../../assets/images/tool4.png" alt=""></div>
                        <div class="tool-item-title">
                            <div class="tool-item-text">{{guanggao[5]?guanggao[5].title:'服务产品，突破想象'}}</div>
                            <div class="tool-item-p">{{guanggao[5]?guanggao[5].content:'服务产品，突破想象'}}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="jieshao-wrap">
            <img :src="guanggao[6]?guanggao[6].pic_url:require('../../../assets/images/gongjubj.png')" alt="">
            <!-- <div class="jiehsao-img">
                <img src="../../../assets/images/gongju.png" alt="">
            </div>
            <div class="jiehsao-jia"><i class="el-icon-plus"></i></div>
            <div class="jieshao">
                <div class="jieshao-item">
                    <div class="jieshao-text">服务产品，突破想象</div>
                    <div class="jieshao-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘取得真实询盘</div>
                </div>
                <div class="jieshao-item">
                    <div class="jieshao-text">服务产品，突破想象</div>
                    <div class="jieshao-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘取得真实询盘</div>
                </div>
            </div> -->
        </div>

        <div class="bottom-img">
            <img  :src="guanggao[7]?guanggao[7].pic_url:require('../../../assets/images/gongju-bj.jpg')" alt="">
        </div>

    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import banner from '../../../components/banner'
    import {getplates,getplatexts,getplateAds} from '../../../api/index';
    export default {
        data() {
            return {
                loading:false,
                id:'',
                title:'',
                ggList:[],
                guanggao:[]

            }
        },
        components:{
            banner
        },
         computed: {
            ...mapState(['wid'])         
        },
        watch: {
            wid(val){
                console.log(val)
                this.id = val;
                // if(this.id==4){
                //     this.title='外贸营销工具'
                // }else if(this.id==129){
                //     this.title='3D动画演示'
                // }else if(this.id==130){
                //     this.title='产业大数据'
                // }else if(this.id==131){
                //     this.title='全景VR'
                // }else if(this.id==132){
                //     this.title='外贸建站'
                // }
                this.getName()
                this.getplatexts();
                this.getplateAds()
            }
        },
        created() {
            this.id=this.$route.query.wid
            // console.log(this.id)
            // if(this.id==4){
            //     this.title='外贸营销工具'
            // }else if(this.id==129){
            //     this.title='3D动画演示'
            // }else if(this.id==130){
            //     this.title='产业大数据'
            // }else if(this.id==131){
            //     this.title='全景VR'
            // }else if(this.id==132){
            //     this.title='外贸建站'
            // }
            this.getName()
            
            this.getplatexts()
            this.getplateAds()
        },
        methods:{
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:this.id,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.guanggao=res.data
                    }
                })
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 获取外贸营销工具
            getplatexts(){ 
                // this.loading=true;
                getplatexts({
                    plate_id:this.id,
                    recom:1,
                }).then((res)=>{
                    // console.log(res)
                    setTimeout(() => {
                        this.loading=false;
                    }, 200);                    
                    if (res.code==200) {
                        this.ggList=res.data.data.slice(0,20);
                    }
                })
            },
            //获取分类名字
            getName(){
                getplates({
                    p_id:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        // this.fuwuList=res.data
                        for (let i = 0; i < res.data.length; i++) {
                             if (this.id==res.data[i].id) {
                                 this.title=res.data[i].name
                                 return;
                             }
                        }
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
        }
        
    }
</script>

<style lang="less" scoped>
.gengduo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
    .center-text{
        margin-top: 0;
    }
    .more{
        font-size: 16px;
        color: #fe6d00;
        text-decoration: underline;
        cursor: pointer;
    }
}
.gongju-wrap{
    margin: auto;
    .tuijian-list{
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .tuijian-item{
            width: 18%;        
            background: #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 16px 5px rgba(59, 121, 204, 0.18);
            margin-right: 2.5%;
            margin-bottom: 35px;
            border-radius: 15px;
            padding: 35px 0;
            cursor: pointer;
            &:nth-child(5n+0){margin-right: 0;}
            .tuijian-img{
                width: 70%;height:auto;margin:auto;text-align:center;
                img{width: auto;height:50px;margin:0 auto;}
            }
            .tuijian-p{ margin: 0 10px;margin-top: 15px;color: #333333;font-size: 20px;font-weight: bold;font-family: Arial;text-align: center;}

        }
    }
    .meiyong-wrap{
        width: 94%;margin: auto;padding-bottom:25px;
        .meiyong-text{
            margin: 45px 0;text-align: center;
            .meiyong-p{color: #333333;font-size: 33px;}
            .meiyong-p1{color: #666666;font-size: 16px;font-weight: 300;margin-top: 15px;}
        }
        .meiyong-img{
            width: 100%;
            img{width: 100%;}    
        }
    }
}

.tool-wrap{
    width: 100%;
    background: #f6f6f6;
    padding: 60px 0;
    .tool{
        margin: auto;
        .tool-title{
            margin-bottom:50px;
            .tool-text{
                display: flex;align-items: center;justify-content: center;
                p{width: 190px;height: 1px;background: #b0b0b0;}
                .tool-p{color: #333333;font-size: 33px;margin: 0 30px;}
            }
            .tool-p1{color: #666666;font-size: 16px;font-weight: 300;margin-top: 10px;text-align: center;}
        }
        .tool-list{
            display: flex;
            justify-content: space-between;
            .tool-item{
                width: 22%;
                .tool-img{
                    width: 100%;
                    img{width: 100%;}
                }
                .tool-item-title{
                    padding: 30px 35px;
                    background: #fff;
                    box-shadow: 0px 2px 5px 0px #ccc;
                    text-align: center;
                    .tool-item-text{
                        color: #F8752D;
                        font-size: 20px;
                    }
                    .tool-item-p{
                        color: #333;
                        font-size: 14px;
                        font-weight: 300;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

.jieshao-wrap{
    width: 1200px;
    padding: 40px 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        width: 100%;
        display: flex;
    }
    .jiehsao-img{
        width: 569px;
        img{width: 100%;height: auto;}
    }
    .jiehsao-jia{
        width: 64px;
        height: 64px;
        background: #0071DB;
        border-radius: 50%;
        line-height: 64px;
        text-align: center;
        color: #fff;
        font-size: 45px;
    }
    .jieshao{
        .jieshao-item{
            width: 280px;
            padding: 35px 40px;
            background: #FFFFFF;
            box-shadow: 0px 0px 19px 2px rgba(28, 123, 192, 0.32);
            border-radius: 5px;
            margin: 50px 0;
            .jieshao-text{
                color: #222;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
            }
            .jieshao-p{
                color: #333333;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
}

.bottom-img{
    width: 100%;height: auto;cursor: pointer;
    img{width: 100%;height: auto;display: flex;}
}


</style>